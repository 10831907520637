/* reset CSS :: Start */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #5c6670;
}
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #5c6670;
    }
/* Track */
::-webkit-scrollbar-track {
    background: #d6d9db;
}
/* The track NOT covered by the handle.
::-webkit-scrollbar-track-piece {
  background: #212121; 
}
/* Corner */
::-webkit-scrollbar-corner {
    background: #5c6670;
}
/* Resizer */
::-webkit-resizer {
    background: #5c6670;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1.4;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

table {
    border-collapse: collapse;
    border-spacing: 0;
}
/* reset CSS :: End */
body {
    font-family: 'Readex Pro', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    background: #fff;
    color: #212121;
    font-weight: 400;
}

strong, b {
    font-weight: 700;
}
/*** Global CSS :: Start ***/
button:focus {
    outline: none;
}

a, a.btn {
    color: #212121;
    text-decoration: none;
    transition: 0.4s;
}

    a:hover, a:focus, a:focus-visible {
        color: #91AE28;
        text-decoration: none;
        transition: 0.4s;
        text-shadow: 0 1px 2px rgba(0,0,0,.10);
        outline: none;
    }

img {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Readex Pro', sans-serif;
    font-weight: 700;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 42px;
}

h3 {
    font-size: 36px;
}

h4 {
    font-size: 30px;
}

h5 {
    font-size: 24px;
}

h6 {
    font-size: 20px;
}

p {
    margin: 0 0 20px;
}

.p-0 {
    padding: 0 !important;
}

.p-5 {
    padding: 5px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-25 {
    padding: 25px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-50 {
    padding: 50px !important;
}

.p-60 {
    padding: 60px !important;
}

.p-70 {
    padding: 70px !important;
}

.p-l-0 {
    padding-left: 0px !important;
}

.p-l-5 {
    padding-left: 5px !important;
}

.p-l-10 {
    padding-left: 10px !important;
}

.p-l-15 {
    padding-left: 15px !important;
}

.p-l-20 {
    padding-left: 20px !important;
}

.p-l-25 {
    padding-left: 25px !important;
}

.p-l-30 {
    padding-left: 30px !important;
}

.p-l-40 {
    padding-left: 40px !important;
}

.p-l-50 {
    padding-left: 50px !important;
}

.p-l-60 {
    padding-left: 60px !important;
}

.p-l-70 {
    padding-left: 70px !important;
}

.p-r-0 {
    padding-right: 0px !important;
}

.p-r-5 {
    padding-right: 5px !important;
}

.p-r-10 {
    padding-right: 10px !important;
}

.p-r-15 {
    padding-right: 15px !important;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-25 {
    padding-right: 25px !important;
}

.p-r-30 {
    padding-right: 30px !important;
}

.p-r-40 {
    padding-right: 40px !important;
}

.p-r-50 {
    padding-right: 50px !important;
}

.p-r-60 {
    padding-right: 60px !important;
}

.p-r-70 {
    padding-right: 70px !important;
}

.p-t-0 {
    padding-top: 0px !important;
}

.p-t-5 {
    padding-top: 5px !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-15 {
    padding-top: 15px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-t-25 {
    padding-top: 25px !important;
}

.p-t-30 {
    padding-top: 30px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-t-50 {
    padding-top: 50px !important;
}

.p-t-60 {
    padding-top: 60px !important;
}

.p-t-70 {
    padding-top: 70px !important;
}

.p-b-0 {
    padding-bottom: 0px !important;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-b-25 {
    padding-bottom: 25px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-b-40 {
    padding-bottom: 40px !important;
}

.p-b-50 {
    padding-bottom: 50px !important;
}

.p-b-60 {
    padding-bottom: 60px !important;
}

.p-b-70 {
    padding-bottom: 70px !important;
}

.m-0 {
    margin: 0 !important;
}

.m-5 {
    margin: 5px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-25 {
    margin: 25px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-40 {
    margin: 40px !important;
}

.m-50 {
    margin: 50px !important;
}

.m-60 {
    margin: 60px !important;
}

.m-70 {
    margin: 70px !important;
}

.m-l-0 {
    margin-left: 0px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-l-10 {
    margin-left: 10px !important;
}

.m-l-15 {
    margin-left: 15px !important;
}

.m-l-20 {
    margin-left: 20px !important;
}

.m-l-25 {
    margin-left: 25px !important;
}

.m-l-30 {
    margin-left: 30px !important;
}

.m-l-40 {
    margin-left: 40px !important;
}

.m-l-50 {
    margin-left: 50px !important;
}

.m-l-60 {
    margin-left: 60px !important;
}

.m-l-70 {
    margin-left: 70px !important;
}

.m-r-0 {
    margin-right: 0px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-r-20 {
    margin-right: 20px !important;
}

.m-r-25 {
    margin-right: 25px !important;
}

.m-r-30 {
    margin-right: 30px !important;
}

.m-r-40 {
    margin-right: 40px !important;
}

.m-r-50 {
    margin-right: 50px !important;
}

.m-r-60 {
    margin-right: 60px !important;
}

.m-r-70 {
    margin-right: 70px !important;
}

.m-t-0 {
    margin-top: 0px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-25 {
    margin-top: 25px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-t-50 {
    margin-top: 50px !important;
}

.m-t-60 {
    margin-top: 60px !important;
}

.m-t-70 {
    margin-top: 70px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-25 {
    margin-bottom: 25px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-b-50 {
    margin-bottom: 50px !important;
}

.m-b-60 {
    margin-bottom: 60px !important;
}

.m-b-70 {
    margin-bottom: 70px !important;
}

.w-full {
    width: 100%;
}

.w-half {
    width: 50%;
}
.w-p80 {
    width: 80%;
}

.w-16 {
    width: 16px !important;
}

.w-30 {
    width: 30px !important;
}

.w-20 {
    width: 20px !important;
}

.w-40 {
    width: 40px !important;
}

.w-50 {
    width: 50px !important;
}

.w-70 {
    width: 70px !important;
}

.w-80 {
    width: 80px !important;
}

.w-100 {
    width: 100px !important;
}

.w-120 {
    width: 120px !important;
}

.w-130 {
    width: 130px !important;
}

.w-150 {
    width: 150px !important;
}

.w-200 {
    width: 200px !important;
}

.max-width-full {
    max-width: 100%;
}

.p-relative {
    position: relative;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

 
.text-theme {
    color: #F7921E !important;
}

.text-gray {
    color: #999 !important;
}

.text-light-gray {
    color: #888888 !important;
}

.text-green {
    color: #91AE28 !important;
}

.text-blue {
    color: #29ABE2 !important;
}

.text-red {
    color: #F00 !important;
}

.text-yellow {
    color: #FFD700 !important;
}

.text-black {
    color: #212121 !important;
}

.text-underline {
    text-decoration: underline;
}

.text-decoration-none {
    text-decoration: none;
}

.f-11 {
    font-size: 11px;
}

.f-12 {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-14 {
    font-size: 14px;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-22 {
    font-size: 22px;
}

.f-24 {
    font-size: 24px;
}

.f-30 {
    font-size: 30px;
}

.f-36 {
    font-size: 36px;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.float-r {
    float: right;
}

.float-l {
    float: left;
}

.clearfix {
    clear: both;
}

label {
    margin: 0 0 5px;
}

.modal-body label span {
    color: #F00;
    margin: 0 0 0 5px;
}

.form-group.icon-input .form-control {
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 12px 12px 12px 45px;
    opacity: 1.1;
    background: #FFF;
}

    .form-group.icon-input .form-control.m-i-icon {
        padding: 12px 36px 12px 45px;
    }

.login-info {
    border-radius: 6px;
    background: #F5F5F5;
    padding: 20px;
    height: 100%;
}

.social-icon li {
    margin-right: 30px;
    display: flex;
    align-items: center;
}

    .social-icon li:last-child {
        margin-right: 0;
    }

    .social-icon li a.btn-circle {
        height: 42px;
        width: 42px;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: none;
        color: #fff;
        border: none;
    }

        .social-icon li a.btn-circle.google {
            background: #fff;
            border: 1px solid #999999;
        }

        .social-icon li a.btn-circle.facebook {
            background: #4064AD;
        }

        .social-icon li a.btn-circle.linked {
            background: #0070AC;
        }

.login-info-text label.form-check-label {
    color: rgba(0, 0, 0, 0.40);
}

.display-none {
    display: none;
}

.form-group {
    margin: 0 0 20px;
    position: relative;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999;
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999;
    opacity: 1;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #999;
    opacity: 1;
}

.form-check-input[type=checkbox] {
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    width: 20px;
    height: 20px;
}

.form-check-input:checked {
    background-color: #F7921E;
    border-color: #F7921E;
}

.form-switch .form-check-input[type=checkbox] {
    border-radius: 3rem;
    border-radius: 3rem;
    width: 2.4rem;
    height: 1.4rem;
    cursor: pointer;
}

.form-switch {
    padding-left: 0 !important;
    display: flex;
    align-items: center;
}

    .form-switch label {
        font-size: 18px;
        margin: 0 20px 0 0;
    }

    .form-switch .form-check-input {
        width: 2em;
        margin-left: 0;
    }


.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 198, 39,0.1);
}

.form-inline-block {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

    .form-inline-block.center {
        align-items: center;
    }

        .form-inline-block.center.space-between {
            justify-content: space-between;
        }

.input-line:focus {
    outline: none;
}

.input-line > .form-control, .input-line > .btn, .input-line > div {
    display: inline;
    margin: 0 7px;
    text-align: center;
}

.form-control {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    padding: 12px 16px;
    min-height: 36px;
    color: #212121;
    opacity: 1;
    font-size: 16px;
    line-height: 1;
    border-radius: 6px;
    /* box-shadow: 0 2px 4px rgba(0,0,0,.15); */
}

    .form-control.sm {
        font-size: 13px;
        min-height: 30px;
        padding: 4px 8px;
    }

    .form-control:focus {
        background: #fff;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.60);
    }

    .form-control.valid {
        border-color: #2CBB68;
    }

    .form-control:disabled {
        background-color: #f2f2f2;
        cursor: not-allowed;
    }

.alert.alert-danger {
    padding: 6px 12px;
    color: #F00;
    font-size: 12px;
    margin-bottom: 5px;
}

.error .form-control,
.error .form-select,
.error .form-check {
    border: 1px solid #F00;
    box-shadow: 1px 3px 4px 0px rgba(255, 0, 0, 0.25) inset;
}

span.text-red.material-icons {
    display: none;
}

.error span.text-red.material-icons {
    position: absolute;
    top: 42px;
    right: 12px;
    display: inline-block;
}

.error .error-text {
    color: #F00;
    font-size: 12px;
    font-weight: 200;
    position: absolute;
    bottom: -17px;
    display: block;
}

.form-group .error-text {
    display: none;
}

.form-select {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    padding: 10px 12px;
    min-height: 36px;
    color: #212121;
    opacity: 1;
    font-size: 14px;
    line-height: 1;
}

    .form-select.sm {
        font-size: 13px;
        min-height: 30px;
        padding: 4px 8px;
    }

    .form-select:focus {
        border: none;
        box-shadow: none;
    }

.table th {
    font-family: 'Readex Pro', sans-serif;
    color: #212121;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 10px;
    text-align: center;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    background: #f8f8f8;
    --bs-table-accent-bg: #f8f8f8;
    vertical-align: middle;
    padding: 15px 10px;
    text-align: center;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
    background: #fff;
    text-align: center;
    vertical-align: middle;
    padding: 15px 10px;
}

.vertical-center tr {
    vertical-align: middle;
}

.table td .info-meta.flag {
    justify-content: center;
}

td .info-meta.company img {
    height: 28px;
}

.custom-select {
    background-color: #F5F5F5;
    border: 1px solid #d6d9db;
    border-radius: 42px;
    padding: 10px 20px;
    min-height: 42px;
    color: #212121;
    opacity: 1;
    font-size: 15px;
}

.form-group label {
    font-size: 16px;
    line-height: 1.4;
    margin: 0 0 10px;
    color: #888;
}

    .form-group label.dark {
        color: #212121;
    }

.form-group textarea {
    resize: none;
    height: 120px;
    border-radius: 6px;
}

.select-arrow {
    color: #5c6670;
    font-size: 15px;
    position: absolute;
    bottom: 9px;
    right: 10px;
    z-index: 1;
}

.custom-control-label {
    margin: 0;
    padding-top: 4px;
    padding-left: 5px;
    cursor: pointer;
}

    .custom-control-label::after {
        width: 15px;
        height: 15px;
    }

    .custom-control-label:focus {
        outline: none;
    }

    .custom-control-label::before {
        height: 20px;
        width: 20px;
    }

.custom-control-input {
    cursor: pointer;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    width: 12px;
    height: 12px;
    background: #FFC627;
    border-radius: 50%;
    background-image: none !important;
    top: 8px !important;
    left: -20px;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}

.custom-control-label::after {
    top: 5px;
}

.custom-control-label::before {
    border: #5c6670 solid 1px;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #FFC627;
    background-color: #FFC627;
}

.custom-control-input:checked ~ .custom-control-label::after {
    border-radius: 3px;
    height: 22px;
    width: 22px;
    top: 3px;
    left: -25px;
}

.switch-wrap {
    display: flex;
    align-items: center;
}

    .switch-wrap .switch-label {
        margin-right: 10px;
        min-width: 30px;
    }

.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 26px;
}

    .switch input {
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #F8F8FB;
    border: 1px solid #d6d9db;
    -webkit-transition: .4s;
    transition: .4s;
    pointer-events: none;
}

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        border-radius: 50%;
        bottom: -1px;
        left: 0;
        background-color: #d6d9db;
        -webkit-transition: .4s;
        transition: .4s;
    }

input:checked + .slider {
    background-color: #F8F8FB;
}

input:focus + .slider {
    box-shadow: 0 0 1px #F8F8FB;
}

input:checked + .slider:before {
    background-color: #FFC627;
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
}
/**.divider {
  border-bottom: 1px solid #d6d9db;
  background: transparent;
  height: 1px;
  clear: both;
  padding: 0 0 15px;
  margin: 0 0 15px;
}**/
.divider {
    background: #CCCCCC;
    width: 2px; /**1**/
    height: 24px;
    border-radius: 12px;
}

.border-gray {
    border: 1px solid #d6d9db;
}

.border-bottom {
    border-bottom: 1px solid #d6d9db;
}

.pointer {
    cursor: pointer;
}

.img-responsive {
    max-width: 100%;
}

.m-w-350 {
    max-width: 350px;
}

.d-flex {
    display: flex;
}

.align-item-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.img-responsive {
    display: block;
    max-width: 100%;
}

.btn {
    font-weight: 600;
    font-size: 14px;
    border-radius: 6px;
    line-height: 1.1;
    text-align: center;
    padding: 10px 15px;
    transition: 0.4s;
    background: transparent;
    box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .btn:hover {
        background: #F7921E;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
        color: #fff;
    }
    /* .btn:active{
  transform: translateY(0);
} */
    .btn.focus, .btn:focus {
        box-shadow: none;
    }

.btn-orange, a.btn-orange {
    color: #fff;
    background-color: #F7921E;
}

    .btn-orange:hover, .btn-orange:active, .btn-orange:focus, .btn-orange:focus-visible {
        color: #fff !important;
        background-color: #E7891D !important;
        border: 1px solid transparent !important;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    }

.btn-dark {
    border: 1px solid #212121;
    color: #fff;
    background: #212121;
}

    .btn-dark:hover, .btn-dark:active, .btn-dark:focus, .btn-dark:focus-visible {
        background: #FFF;
        color: #181818;
        border: 1px solid #181818;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    }

.btn-dark-outline {
    border-radius: 6px;
    border: 1px solid #212121;
    background: #FFF;
}

    .btn-dark-outline:hover, .btn-dark-outline:active, .btn-dark-outline:focus, .btn-dark-outline:focus-visible {
        background: #f5f5f5;
        color: #212121;
        border: 1px solid #212121;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    }

.btn-orange-outline {
    border: 1px solid #F7921E;
    color: #F7921E;
}

    .btn-orange-outline:hover, .btn-orange-outline:active, .btn-orange-outline:focus, .btn-orange-outline:focus-visible {
        border: 1px solid #F7921E;
        color: #fff;
        background: #F7921E;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    }

.btn-red-outline {
    border: 1px solid #e74c3c;
    background: #FFF;
    color: #e74c3c;
    border-radius: 6px;
}

    .btn-red-outline:hover, .btn-red-outline:active, .btn-red-outline:focus, .btn-red-outline:focus-visible {
        border: 1px solid #c84334;
        background: #c84334;
        color: #fff;
        border-radius: 6px;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    }

.btn-green {
    background: #91AE28;
    color: #FFF;
    font-size: 16px;
}

    .btn-green:hover, .btn-green:active, .btn-green:focus, .btn-green:focus-visible {
        background-color: #87A225;
        border-color: 1px solid transparent;
        color: #fff;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    }

.applied-btn {
    background: rgba(247, 146, 30, 0.10) !important;
    color: #F7921E !important;
    pointer-events: none;
}

    .applied-btn:hover, .applied-btn:active, .applied-btn:focus, .applied-btn:focus-visible {
        background: rgba(247, 146, 30, 0.10) !important;
        color: #E7891D !important;
        pointer-events: none;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    }

.btn-circle {
    height: 42px;
    width: 42px;
    min-height: 42px;
    min-width: 42px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,.15);
    background: rgba(247, 146, 30, 0.10);
    color: #F7921E;
    transparent: 0.4s;
}

.btn.btn-circle.light {
    background: rgba(247, 146, 30, 0.10);
    border-radius: 50% !important;
    border: transparent;
    box-shadow: none !important;
}

    .btn.btn-circle.light span.m-icon {
        font-size: 22px;
    }

.btn-circle:hover, .btn-circle:active, .btn-circle:focus, .btn-circle:focus-visible {
    color: #E7891D;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
}

.btn.btn-circle.light:hover, .btn.btn-circle.light:active, .btn.btn-circle.light:focus, .btn.btn-circle.light:focus-visible {
    background: rgba(247, 146, 30, 0.15);
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
}

.btn-circle.small {
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
}

    .btn-circle.small .m-icon {
        font-size: 18px;
    }

.btn-icon {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border: none;
    background: transparent;
}

    .btn-icon:hover {
        background: transparent;
        box-shadow: none;
    }

    .btn-icon.white .m-icon {
        color: #fff;
    }

        .btn-icon.white .m-icon:hover {
            color: #fff;
            opacity: 0.9;
        }

    .btn-icon.green .m-icon {
        color: #2CBB68;
    }

        .btn-icon.green .m-icon:hover {
            color: #2CBB68;
            opacity: 0.9;
        }

    .btn-circle .m-icon, .btn-icon .m-icon {
        font-size: 22px !important;
        line-height: 1;
        margin: 0 !important;
    }

        .btn-circle .m-iconhover, .btn-icon .m-icon:hover {
            color: #414142;
        }

    .btn-circle.sm, .btn-icon.sm {
        height: 30px;
        width: 30px;
        min-height: 30px;
        min-width: 30px;
        padding: 0;
    }

        .btn-circle.sm .m-icon, .btn-icon.sm .m-icon {
            font-size: 16px !important;
            margin: 0;
        }

.btn-link, a.btn-link {
    box-shadow: none;
    border-radius: 0;
    color: #F7921E;
    padding: 0;
    font-weight: 600;
    border: none;
    background: transparent;
    line-height: 0;
    text-decoration: none;
    /* display: inline-block; */
}

    .btn-link:hover, .btn-link:active, .btn-link:first-child:active {
        color: #F7921E !important;
        text-decoration: none !important;
        background: transparent !important;
        box-shadow: none !important;
    }

.btn-link, a.btn-link {
    color: #212121;
}

    .btn-link:hover, a.btn-link:active {
        color: #212121;
    }

.btn-outline-dark, a.btn-outline-dark {
    border: 2px solid #414142;
    color: #414142;
    box-shadow: none;
    background: transparent;
}

    .btn-outline-dark:hover, .btn-outline-dark:active, .btn-outline-dark:focus, .btn-outline-dark:focus-visible {
        border: 2px solid #414142;
        color: #fff !important;
        background: #414142 !important;
    }

.btn-gray {
    padding: 10px 12px;
    background: #808080;
    color: #FFF;
}

    .btn-gray:hover, .btn-gray:active, .btn-gray:focus, .btn-gray:focus-visible {
        background: #707070;
        color: #FFF;
        outline: none;
        box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    }

.btn-sm {
    padding: 7px 10px;
    font-size: 13px;
}

    .btn-sm:hover {
        box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.16);
    }

.btn-lg {
    font-size: 16px;
    padding: 10px 15px;
}

.btn i {
    margin-right: 3px;
}

.btn .m-icon {
    font-size: 18px;
}

.link {
    color: #6c5ce7;
    font-weight: 500;
    text-decoration: none;
    transform: 0.4s;
}

    .link:hover {
        color: #414142;
        text-decoration: underline;
    }

.d-grid .btn-block {
    width: 100%;
    display: flex;
}

.btn-block {
    width: 100%;
    display: block;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
}

    .button-group .btn {
        margin-right: 10px;
    }

        .button-group .btn:last-child {
            margin-right: 0;
        }

.breadcrumb {
    margin: 0;
    width: 100%;
    font-family: 'Readex Pro', sans-serif;
    font-size: 18px;
}

    .breadcrumb .breadcrumb-link {
        font-weight: 500;
        margin: 0 5px;
    }

.list-grid-toggle {
    display: flex;
    flex-wrap: nowrap;
}

    .list-grid-toggle .m-icon {
        font-size: 24px;
    }

    .list-grid-toggle .active .m-icon {
        color: #FFC627;
    }

.progress {
    background: #d6d9db;
}

.progress-bar {
    background: #FFC627;
}

.panel-wrap {
    margin: 0 0 40px;
}

.card {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 20px;
}

.accordion-wrapper .accordion-btn {
    padding: 20px 10px 20px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    width: 100%;
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion-body {
    padding: 20px 30px 0;
}

.scroll-md {
    height: 162px;
    overflow: auto;
}

.scroll-md-auto {
    max-height: 162px;
    overflow: auto;
}

.scroll-lg-auto {
    max-height: 300px;
    overflow: auto;
}

.modal-backdrop {
    background-color: #212121;
    opacity: .7;
}
    .modal-backdrop.show {
        background-color: #212121;
        opacity: .9 !important;
    }

.modal-open .modal {
    padding: 0 !important;
}

h4.modal-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    color: #212121;
}

.modal-footer {
    padding: 10px 20px;
    background: #fff;
    border-radius: 0;
}

    .modal-footer button {
        min-width: 70px;
    }

.modal-dialog {
    max-width: 840px;
}

.modal-sm {
    max-width: 420px !important;
}

.modal-md {
    max-width: 640px !important;
}

.modal-xl {
    max-width: 1180px;
}

.modal-body {
    padding: 30px 20px;
    /**max-height: 75vh;**/
    overflow: auto;
}

.modal-header {
    padding: 15px 20px;
    border-radius: 0;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    background: #FFF;
}

.modal-content {
    border-radius: 0;
    border: none;
    background: #FFF;
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.20);
}

.verification-mail {
    color: rgba(33, 33, 33, 0.60);
}

.modal-content .btn-close {
    opacity: 1;
    padding: 0;
    top: 27px;
    position: absolute;
    right: 27px;
    box-shadow: none;
}

.login-info h1 {
    margin-bottom: 30px;
}

.login-info-text {
    margin: 30px 0;
}

.form-group.icon-input span.m-icon {
    position: absolute;
    top: 42px;
    left: 12px;
}

.form-group.icon-input span.m-i-icon {
    position: absolute;
    top: 42px;
    right: 12px;
    display: inline-block;
}

.d-grid .btn-block {
    font-size: 16px;
}

.or-divider {
    margin: 30px 0;
    position: relative;
    text-align: center;
    z-index: 1;
    width: 100%;
    height: 1px;
    background: #888;
}

    .or-divider span {
        position: absolute;
        top: -10px;
        background: #fff;
        padding: 0 20px;
        color: #888;
        font-size: 16px;
        margin: auto;
        left: 0;
        right: 0;
        width: 170px;
    }

.social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.container-fluid {
    padding: 0 60px;
}

.container-sm {
    max-width: 640px;
    margin: auto;
}

.badge {
    margin: 0 10px 10px 0px;
    padding: 6px 12px;
    border-radius: 50px;
    border: 1px solid rgba(33, 33, 33, 0.50);
    color: rgba(33, 33, 33, 0.60);
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
}

    .badge.skills {
        color: #212121;
        background-color: #FFEFDD;
        border-color: transparent;
    }

    .badge.industry-types {
        color: #212121;
        background-color: #ADFFEA;
        border-color: transparent;
    }

    .badge.compliance-category {
        color: #212121;
        background-color: #CDF8FF;
        border-color: transparent;
    }

    .badge.compliance-standard {
        color: #212121;
        background-color: #FFE3E1;
        border-color: transparent;
    }

.btn-search-group {
    margin: 0 0 10px 0;
    display: flex;
    flex-wrap: wrap;
}

    .btn-search-group .btn {
        box-shadow: none;
        cursor: text;
        margin: 0 10px 10px 0;
        display: flex;
        align-items: center;
        font-weight: 400;
    }

.bg-orange {
    background: #F7921E;
}

.bg-gray {
    background: #F8F8F8;
}

.bg-green {
    background: #91AE28;
}

.material-icons-two-tone.gray {
    color: #5c6670;
    -webkit-filter: invert(41%) sepia(9%) saturate(577%) hue-rotate(169deg) brightness(91%) contrast(89%);
    filter: invert(41%) sepia(9%) saturate(577%) hue-rotate(169deg) brightness(91%) contrast(89%);
}

.material-icons-two-tone.bg-yellow {
    color: #5c6670;
    -webkit-filter: invert(92%) sepia(13%) saturate(4244%) hue-rotate(334deg) brightness(102%) contrast(101%);
    filter: invert(92%) sepia(13%) saturate(4244%) hue-rotate(334deg) brightness(102%) contrast(101%);
}

.info-block-wrap .info-block {
    margin: 0;
}

.info-block-wrap.one-col {
    display: flex;
    flex-wrap: wrap;
}

    .info-block-wrap.one-col .info-block {
        width: 100%;
    }

.info-block-wrap.two-col {
    display: flex;
    flex-wrap: wrap;
}

    .info-block-wrap.two-col .info-block {
        width: 50%;
    }

.info-block-wrap.three-col {
    display: flex;
    flex-wrap: wrap;
}

    .info-block-wrap.three-col .info-block {
        width: 33%;
    }

.info-block .label {
    font-size: 13px;
    font-weight: 700;
    font-family: 'Readex Pro', sans-serif;
    color: #5c6670;
    margin: 0 0 2px;
    display: block;
}

.info-block .value {
    font-size: 13px;
    display: block;
    color: #414142;
    margin: 0;
}

.info-block.inline {
    display: flex;
    flex-wrap: nowrap;
}

    .info-block.inline .label {
        width: 110px;
        margin-right: 10px;
    }

    .info-block.inline .value {
        width: 100%;
    }

.oneline-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.page-item {
    display: flex;
    align-items: center;
}

.active > .page-link {
    color: #212121;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
}

.page-link:hover {
    background: rgba(247, 146, 30, 0.10) !important;
}

.page-link.active {
    color: #fff;
    background: #F7921E !important;
    border-color: transparent;
}

.page-link {
    color: #212121;
    line-height: 1;
    padding: 8px 10px 5px;
}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
    border-radius: 0 !important;
}

.tab-content {
    width: 100%;
}
/*** Global CSS :: End ***/

/***welcome-sec CSS :: start ***/
.page-wrapper {
    padding: 67px 0 0 0;
}

.section {
    padding: 80px 0;
}

.welcome-sec {
    display: flex;
}

    .welcome-sec .left-sec {
        padding: 60px;
        width: 60%;
        position: relative;
        z-index: 1;
        background-color: transparent;
    }

    .welcome-sec .section-desc {
        margin: 30px 0;
       
        font-size: 18px;
    }

.search-group {
    background: rgba(247, 146, 30, 0.10);
    border-radius: 6px;
    padding: 5px;
    max-width: 480px;
    width: 100%;
}

.search-info {
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 6px;
    padding: 5px 9px 5px 16px;
}

.search-group a.btn.btn-link:active, .search-group a.btn.btn-link:focus-visible {
    border: none;
    background-color: #fff;
    color: #212121;
    transform: inherit !important;
}

.search-group span.m-icon {
    margin-right: 5px;
}

ul.dropdown-menu.job-list.show::after {
    top: -10px;
    left: 5px;
}

ul.dropdown-menu.job-list.show li {
    padding: 10px 20px;
}

    ul.dropdown-menu.job-list.show li a.dropdown-item {
        padding: 0;
    }

.dropdown-item:focus, .dropdown-item:hover {
    color: #212121;
    background-color: #fff;
}

.left-sec .job-list {
    margin: 25px 0 0 0 !important;
    padding: 0;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15)
}

.search-group .divider {
    display: block;
    margin: 0 10px;
    padding: 0 1px;
}

.search-group .form-control {
    border: none;
    border-radius: 0;
    color: #999;
    font-size: 14px;
    padding: 12px 12px 12px 6px;
}

.search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    padding: 10px 12px;
}
/**.btn:first-child:active{
  background: #F7921E;
  border: 1px solid transparent;
  box-shadow: none;
  color: #fff;
}**/
:not(.btn-check) + .search-btn:active {
    background: #F7921E;
    border-color: 1px solid transparent;
    box-shadow: none;
    color: #fff;
}

.welcome-sec .right-sec {
    width: 40%;
}

.welcome-sec .img-wrapper {
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    max-width: 50%;
}

.welcome-sec img.img-block {
    object-fit: cover;
    height: auto;
    width: auto;
    min-height: 100%;
    min-width: 100%;
}

.welcome-sec .img-wrapper::after {
    content: " ";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    background: rgb(248,248,248);
    background: -webkit-gradient(linear, left top, right top, from(rgba(248,248,248,1)), color-stop(27.60%, rgba(248,248,248,0.1)), to(rgba(248,248,248,0)));
    background: -o-linear-gradient(left, rgba(248,248,248,1) 0%, rgba(248,248,248,0.1) 27.60%, rgba(248,248,248,0) 100%);
    background: linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(248,248,248,0.1) 27.60%, rgba(248,248,248,0) 100%);
}
/***welcome-sec CSS :: end ***/

/***company-logo CSS :: start***/
.section-sm {
    padding: 40px 0;
}

.company-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    .company-logo h6 {
        margin-bottom: 5px;
    }

.logo-img-block {
    /*display: flex;*/
}

    .logo-img-block img:last-child {
        margin-right: 0;
    }

    .logo-img-block img {
        max-width: 220px;
        width: auto;
        /* max-height: 220px;
  height: auto;
 */ margin-right: 30px;
    }
/***company-logo CSS :: end***/

/***ideal-group CSS :: start ***/
.ideal-group {
    display: flex;
}

    .ideal-group .left-sec {
        width: 50%;
    }

    .ideal-group .img-wrapper {
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        max-width: 50%;
    }

        .ideal-group .img-wrapper::after {
            content: " ";
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            position: absolute;
            background: rgb(255,255,255);
            background: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)), to(rgba(255,255,255,1)));
            background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        }

    .ideal-group img.img-block {
        object-fit: cover;
        height: auto;
        width: auto;
        min-height: 100%;
        min-width: 100%;
    }

.frame-block {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}

    .frame-block img {
        margin-right: 20px;
    }

.frame-data {
    display: flex;
    flex-direction: column;
}

    .frame-data h6 {
        margin-bottom: 10px;
    }

    .frame-data p {
        color: #888888;
        font-size: 16px;
    }

.frame-content-block .section-title h2 {
    margin-bottom: 40px;
}

.frame-content-block {
    padding: 0 60px;
    width: 50%;
    position: relative;
    z-index: 1;
}
/***ideal-group CSS :: end***/

/***how-it-works CSS :: start ***/
.how-it-works .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    position: relative;
}

span.bg-wrap {
    position: absolute;
    left: 0;
    right: 0;
    max-height: 420px;
    background: #F8F8F8;
    top: 0;
    height: 100%;
    z-index: 0;
}

.how-it-works .section-title h1, .faq-block .section-title h1 {
    margin-bottom: 30px;
}

.step-wrapper {
    display: flex;
}

    .step-wrapper .step-block:last-child {
        margin-right: 0;
    }

.tab-wrapper {
    --bs-nav-tabs-border-width: 0;
    border-radius: 6px;
    background: rgba(247, 146, 30, 0.10);
    padding: 5px;
    border-bottom: none;
    margin-bottom: 60px;
}

ul.nav.nav-tabs {
    background: #fff;
    padding: 5px;
    border-radius: 6px;
    border-bottom: none;
}

.tab-btn {
    border: none;
    box-shadow: none;
}

    .tab-btn:hover {
        color: #F7921E;
        background: #fff;
        box-shadow: none;
    }

    .tab-btn.active {
        background: #F7921E;
        color: #fff;
        box-shadow: none;
    }

.step-block {
    box-shadow: 7px 7px 0px rgba(0, 0, 0, 0.15);
    margin-right: 40px;
    width: 100%;
    padding: 35px 20px 29px 20px;
    text-align: center;
}

    .step-block img {
        max-width: 60px;
        height: auto;
        margin: 0 auto 12px auto;
    }

    .step-block h6 {
        /* text-transform: capitalize;*/
        color: #212121;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
        text-align: center;
    }

    .step-block p {
        /* text-transform: capitalize;*/
        color: #888888;
        font-size: 14px;
        text-align: center;
        margin: 0;
    }

.count-block {
    background: #888888;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    position: absolute;
    padding: 3px 20px;
    top: -18px;
    left: 39%;
}

    .count-block strong {
        margin-right: 0;
        color: #fff;
    }
/***how-it-works CSS :: end ***/

/***featured-experts-group CSS :: start ***/
.featured-experts-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.tab-mobile-display {
    display: none;
}
/* .featured-experts-title .btn, .tab-mobile-display .btn{
  border: 1px solid #F7921E;
  box-shadow: none;
  color: #F7921E;
  background: transparent;
}
.featured-experts-title .btn:hover ,.featured-experts-title .btn:active{
  border: 1px solid #F7921E !important;
  box-shadow: none !important;
  color: #F7921E !important;
  background: transparent !important;
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16) !important;
} */
.expert-info-block {
    display: flex;
}

.expert-wrapper-block {
    width: 100%;
    margin-right: 40px;
}

    .expert-wrapper-block .two-line {
        height: 39px;
    }

.expert-profile {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.expert-img img {
    border-radius: 48px;
    margin-right: 10px;
    height: 48px;
    width: 48px;
    max-height: 48px;
    max-width: 48px;
    object-fit: cover;
}

.expert-data .expert-name {
    font-size: 18px;
    margin-bottom: 10px;
    /**cursor: pointer;**/
}
/**.expert-data .expert-name:hover{
  color:#F7921E;
}**/
.expert-data span.expert-desg {
    color: #212121;
    font-size: 14px;
}

.expert-data span {
    color: #888;
    font-size: 12px;
    text-transform: capitalize;
}

.expert-data .ratings {
    margin: 5px 0 0 0;
}

    .expert-data .ratings .m-icon {
        text-transform: none;
    }

.expert-data span strong {
    font-weight: 500;
}

.info-desc span.m-icon, .work-pay strong, .comprehensive-block span.m-icon {
    margin-right: 5px;
}

.work-pay span {
    margin-right: 2px;
}

.info-desc .info-meta {
    margin-bottom: 20px;
}

.work-pay span:last-child {
    margin-right: 0;
}

.text-sm {
    color: #212121;
    font-size: 12px;
    font-weight: 400;
}

.one-line {
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.two-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.three-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break:break-word;
}

.work-pay {
    min-width: 80px;
    text-align: right;
    color: #212121;
}
/**.expert-link .btn.btn-link {
  display: flex;
  align-items: center;
}**/
.expert-link span {
    margin-left: 5px;
}

.expert-info-block .expert-wrapper-block:last-child {
    margin-right: 0;
}
/***featured-experts-group CSS :: end ***/

/***comprehensive-block CSS :: start***/
.comprehensive-block {
    background-image: url(../images/shape.svg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    text-align: center;
}

.comprehensive-block-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.comprehensive-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

    .comprehensive-btn button.btn {
        margin-right: 20px;
    }

        .comprehensive-btn button.btn:last-child {
            margin-right: 0;
        }

.comprehensive-block p {
    margin: 30px 0 0 0;
    font-size: 18px;
}
/***comprehensive-block CSS :: end ***/
/*****footer::start*****/
footer {
    padding: 80px 0;
    text-align: center;
}

.footer-logo img {
    margin-bottom: 20px;
}

footer .contact-info-block {
    margin-bottom: 35px;
    display: inline-block;
}

    footer .contact-info-block span {
        font-size: 18px;
    }

        footer .contact-info-block span.m-icon {
            margin-right: 5px;
        }

footer .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

    footer .social-icon li {
        margin-right: 20px;
        display: flex;
        align-items: center;
    }

    footer .social-icon .btn-circle {
        background: #888888;
        color: #FFFFFF;
        font-size: 20px;
    }

        footer .social-icon .btn-circle:hover {
            box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.16);
            background: #91AE28;
        }

    footer .social-icon li:last-child {
        margin-right: 0;
    }

.menu {
    margin: 40px 0 30px 0;
}

ul.menu-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-list li {
    margin-right: 36px;
}

    .menu-list li a {
        font-size: 16px;
    }

        .menu-list li a:hover {
            color: #F7921E;
        }

    .menu-list li:last-child {
        margin-right: 0;
    }

footer p.footer-desc {
    color: #212121;
    max-width: 900px;
    margin: auto;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
}
/*****footer::end*****/



/***header CSS-COPY :: start ***/
body.offcanvas-active {
    overflow: auto;
}

.offcanvas-header {
    display: none;
}

.screen-darken {
    height: 100%;
    width: 0%;
    z-index: 30;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(34, 34, 34, 0.6);
    transition: opacity .2s linear, visibility 0.2s, width 2s ease-in;
}

    .screen-darken.active {
        z-index: 1029;
        transition: opacity .3s ease, width 0s;
        opacity: 1;
        width: 100%;
        visibility: visible;
    }
/***header CSS-COPY :: end ***/


/* ============ mobile view ============ */
@media all and (max-width: 1180px) {

    .offcanvas-header {
        display: block;
    }

    .mobile-offcanvas {
        visibility: hidden;
        transform: translateX(-100%);
        border-radius: 0;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1200;
        width: 80%;
        max-width: 330px;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: visibility .3s ease-in-out, transform .3s ease-in-out;
    }

        .mobile-offcanvas.show {
            visibility: visible;
            transform: translateX(0);
        }

        .mobile-offcanvas .container, .mobile-offcanvas .container-fluid {
            display: block;
        }
}
/* ============ mobile view ============ */

/***header CSS :: start ***/
header .navbar {
    padding: 12px 0;
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
}

header .navbar-brand {
    margin-right: 30px;
}

    header .navbar-brand img {
        height: 32px;
    }

header .menu-bar li.nav-item {
    margin-right: 30px;
    position: relative;
}

    header .menu-bar li.nav-item:last-child {
        margin-right: 0;
    }

ul.navbar-nav.user-info-btn li.nav-item {
    margin-right: 20px;
}

    ul.navbar-nav.user-info-btn li.nav-item:last-child {
        margin-right: 0;
    }

header .menu-bar li.nav-item a.nav-link.active {
    color: #F7921E;
}

    header .menu-bar li.nav-item a.nav-link.active::before {
        content: " ";
        height: 2px;
        background: #F7921E;
        border-radius: 2px;
        bottom: -23.5px;
        position: absolute;
        left: 0;
        right: 0;
    }

header ul.navbar-nav.menu-bar li.nav-item span.m-icon {
    font-size: 18px;
    margin-left: 5px !important;
}

header ul.navbar-nav.menu-bar a.nav-link {
    color: #212121;
    font-size: 15px;
    font-weight: 500;
    padding: 0;
    transition: 0.4s;
}

    header ul.navbar-nav.menu-bar a.nav-link:hover {
        color: #F7921E;
    }
/**.user-info-btn .btn-dark-outline{
  border-radius: 6px;
  border: 1px solid #212121;
  background: #FFF;
}
.user-info-btn .btn-dark-outline:hover{
  color: #212121;
}**/
ul.navbar-nav.user-info li.nav-item {
    position: relative;
    margin-right: 20px;
}

    ul.navbar-nav.user-info li.nav-item:last-child {
        margin-right: 0;
    }
/**header ul.navbar-nav.user-info li.nav-item a{
  border-radius: 6px;
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.16);
  padding: 12px 15px;
  color: #212121;
  font-size: 14px;
}**/
header ul.navbar-nav.user-info li.nav-item a.btn-dark-outline {
    background: transparent;
    color: #212121;
    border: 2px solid #212121;
}

.count {
    color: #fff;
    min-width: 19px;
    padding: 2px 3px;
    width: auto;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    top: -6px;
    right: -15%;
    background: #FF4B55;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
}

.user-img-block img.img-user {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-img-block .text-theme {
    max-width: 90px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

ul.dropdown-menu.dropdown-menu-end a.dropdown-item {
    color: #212121;
}

    ul.dropdown-menu.dropdown-menu-end a.dropdown-item:hover {
        color: #F7921E;
        background: transparent;
    }

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu.show::after {
    display: inline-block;
    margin: auto;
    content: "";
    position: absolute;
    top: -9px;
    left: 5px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #888888;
    border-top: 0;
    z-index: 1;
}

.menu-bar .dropdown-toggle.show::after {
    bottom: -33px;
    left: 5px;
}

.user-dropdown .dropdown-toggle.show::after {
    bottom: -22px;
    left: 5px;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    background-color: #F7921E;
}

.notification-menu.dropdown-toggle.show::after {
    bottom: -18px;
    right: 5px;
}

.offcanvas-header .btn-close {
    position: absolute;
    top: 12px;
    right: 20px;
}

.respo-header {
    display: none;
}

    .respo-header .btn.btn-icon:hover {
        background: transparent;
        box-shadow: none;
    }

ul.navbar-nav.menu-bar span.resp-display {
    display: none;
}

header ul.navbar-nav.ms-auto li.nav-item ul.dropdown-menu.show li a.dropdown-item {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}

.user-info ul.dropdown-menu {
    margin: 12px 0 0 0;
    padding: 0;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.15);
}

.dropdown-menu.dropdown-menu-end.show::after {
    top: -9px;
    right: 5px;
    left: auto;
}

.menu-bar .profile-list {
    margin: 23px 0 0 0;
    padding: 0;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.15);
}

ul.dropdown-menu.profile-list.show::after {
    top: -9px;
    left: 5px;
}

ul.dropdown-menu li a {
    padding: 10px 20px;
    /**border-bottom: 1px solid rgba(0, 0, 0, 0.15);**/
}

li.nav-item.profile-dropdown ul.dropdown-menu li a:hover {
    color: #F7921E;
    background-color: transparent;
}

.mobile-offcanvas ul.navbar-nav.user-info .user-view {
    display: flex;
}

    .mobile-offcanvas ul.navbar-nav.user-info .user-view .m-icon {
        margin: auto 0 auto 7px;
    }

.navbar-nav.user-info .notification-list {
    width: 380px;
    /**border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15);
  margin: 22px 0 0 0 !important;
  padding: 0;**/
}

ul.dropdown-menu.notification-list.show::after {
    top: -9px;
    right: 5px;
}

.notification-list li {
    padding: 16px 20px;
}

    .notification-list li:hover {
        background: #F5F5F5;
    }

.notification-item {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.notification-icon {
    background: #F5F5F5;
    box-shadow: none;
    color: #888888;
    height: 42px;
    width: 42px;
    min-width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    margin-right: 15px;
}

.notification-list span.notification-icon .m-icon {
    font-size: 24px;
}

.notification-list li:hover .notification-icon {
    background: #fff;
}
/*** header CSS :: End ***/

/*** search-talent-pg :: start ***/
.fix-inner-block .sidebar {
    width: 350px;
    overflow: auto;
    height: auto;
    position: fixed;
    left: 0;
    top: 54px;
    bottom: 0;
    padding-bottom: 58px;
    background: #fff;
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.sidebar .sidebar-btn {
    background: #fff;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 350px;
    padding: 10px 20px 10px 10px;
    border-top: 1px solid rgba(33, 33, 33, 0.1);
    border-right: 1px solid rgba(33, 33, 33, 0.1);
}

.sidebar .form-check-input[type=checkbox] {
    margin-right: 10px;
}

.sidebar .form-check-label {
    color: #888;
    font-size: 14px;
}

.accordion-wrapper .accordion-btn .indicator.show {
    display: none;
}

.accordion-wrapper .accordion-btn.collapsed .indicator.show {
    display: block;
}

.accordion-wrapper .accordion-btn.collapsed .indicator.hide {
    display: none;
}

#accordion-rate-range .form-select {
    width: 40%;
    padding: 4px 10px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    position: static;
}

.filter-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #72C04D;
}

.search-compact {
    max-width: 290px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    padding: 1px 13px;
}

    .search-compact .search-info {
        border-radius: 0;
        padding: 0;
    }

    .search-compact .form-control {
        border: none;
        border-radius: 0;
        color: #888;
        font-size: 14px;
        padding: 1px 2px 1px 20px;
    }

    .search-compact .search-info > .m-icon {
        position: absolute;
        top: 10px;
        left: -5px;
        font-size: 20px;
    }

    .search-compact .divider {
        margin: 0 10px 0 0px;
    }

    .search-compact .form-select {
        border: 0;
        padding: 5px 0;
        max-width: 85px;
        min-height: auto;
    }

.pg-content-block {
    padding: 30px 30px 60px 380px;
}

    .pg-content-block .search-group {
        max-width: 100%;
        margin-bottom: 20px;
    }

        .pg-content-block .search-group span.input-m-icon {
            position: absolute;
            top: 12px;
            left: 10px;
        }

    .pg-content-block .form-control {
        padding: 5px 12px 5px 20px;
    }

    .pg-content-block select.form-select {
        margin-right: 20px;
        border: none;
        padding: 5px 12px 5px 5px;
    }

.badge-btn .m-icon {
    margin-left: 10px;
    font-size: 24px;
}

.job-available-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.exports-group .tab-btn {
    color: #999999;
    padding: 0;
    border: none;
    box-shadow: none;
    background: transparent;
}

    .exports-group .tab-btn.active {
        color: #F7921E;
    }

    .exports-group .tab-btn span.m-icon {
        font-size: 30px;
    }

.exports-group .nav-tabs {
    border-bottom: 0;
}

.job-available-block li.nav-item {
    margin-right: 20px;
}

    .job-available-block li.nav-item:last-child {
        margin-right: 0;
    }

.exports-group .card {
    padding: 30px;
    margin-bottom: 30px;
}

    .exports-group .card.grid-block {
        margin-bottom: 0;
        height: 100%;
        display: flex;
        padding: 20px;
    }

.expert-info-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    /* flex-wrap: wrap; */
}

.list-block .expert-name {
    font-size: 22px;
    margin-bottom: 10px;
}

.list-block.grid-block .expert-name {
    margin-bottom: 5px;
}

.expert-detail .m-icon {
    margin: 0 10px 0 20px;
}

.user-block {
    display: flex;
}

.info-meta.flag img {
    width: auto;
    height: 14px;
}

.list-block .work-pay {
    vertical-align:top;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    min-width: 110px;
}

.list-block.grid-block .work-pay {
    font-size: 22px;
}

.month-data {
    font-size: 12px;
    margin-bottom: 20px;
}

.type-name {
    display: flex;
    flex-wrap: wrap;
}

    .type-name .info-meta {
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid #CCC;
        margin-bottom: 15px;
    }

        .type-name .info-meta:last-child {
            margin-right: 0;
            padding-right: 0;
            border-right: none;
            margin-bottom: 15px;
        }

.list-block.grid-block .type-name .info-meta {
    border-right: none;
}

.info-meta {
    display: flex;
    align-items: center;
}

    .info-meta.flag .m-icon {
        font-size: 18px;
        text-transform: none;
    }

.list-block .divider {
    margin: 0 12px 0 20px;
}

.badge-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

    .badge-group a {
        color: #91AE28;
        margin: 0 0 10px 0;
    }

.icon-frame img {
    max-width: 24px;
    max-height: 24px;
}

.fix-inner-block footer {
    padding-left: 350px;
}

.desktop-hide {
    display: none;
}

.grid-wrapper-block {
    display: flex;
}

.expert-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.action-btns {
    display: flex;
    justify-content: flex-end;
}

    .action-btns button.btn {
        margin-right: 20px;
    }

        .action-btns button.btn:last-child {
            margin-right: 0;
        }

.load-more {
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-block .expert-desg {
    margin-right: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.list-block.grid-block .expert-desg {
    margin-right: 10px;
    min-width: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

select.form-select.rate-type {
    position: absolute;
    top: 0;
    left: 70%;
    width: 100px;
    border: none;
    padding: 23px 10px;
}

.textarea-resize textarea.form-control {
    resize: none;
}

.certified-list {
    background: rgba(247, 146, 30, 0.10);
    padding: 10px;
    font-size: 13px;
    border-radius: 6px;
    margin: 0 0 20px;
}
/*** search-talent-pg :: End ***/

/*** job-details-pg :: start ***/
.count-msg {
    color: #fff;
    background: #FF4B55;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    padding: 2px 6px;
    display: block;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    min-width: 20px;
    height: 20px;
    position: absolute;
    right: -18px;
    top: -9px;
    line-height: 15px;
}

.job-details-block .card {
    padding: 30px;
}

.job-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.job-title {
    margin-bottom: 30px;
}

    .job-title h3 {
        font-size: 24px;
    }

.post-info-block {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.company-post {
    display: flex;
    margin-bottom: 30px;
}

.medie-info-img img {
    max-height: 42px;
    margin-right: 12px;
}

.icon-info-block {
    display: flex;
}

.job-details-block .m-icon {
    font-size: 18px;
}

.company-action {
    display: flex;
}

.job-desc-block h6 {
    font-size: 24px;
    margin-bottom: 30px;
}

.job-desc-block p {
    font-size: 16px;
}

.icon-wrapper {
    display: flex;
    margin-bottom: 30px;
}

.company-meta h6 {
    font-size: 24px;
    margin: 0 0 20px 0;
}

.company-meta .badge {
    margin: 0 10px 0 0;
}

.divider-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 1px;
    width: 100%;
    margin: 30px 0;
}
/*** job-details-pg :: end ***/

/*** cookie-policy :: start***/
.cookie-overlay {
    left: 20px;
    right: 20px;
    position: fixed;
    bottom: 20px;
    z-index: 999;
}

    .cookie-overlay a {
        text-decoration: underline;
        color: #6abf16;
    }

    .cookie-overlay .btn {
        box-shadow: none;
        min-width: 70px;
        height: 30px;
    }
/*** cookie-policy :: end ***/

/*** search-job-pg :: start ***/
.job-details-list .medie-info-img img {
    max-height: 24px;
    margin-right: 10px;
}

.job-details-list .expert-profile {
    flex-direction: column;
    align-items: flex-start;
}

img.verified-img {
    max-height: 22px;
}

ul.nav.nav-tabs li.nav-item .filter-btn {
    display: none;
}
/*** search-job-pg :: end ***/



/***dashboard-pg CSS:: start***/
.section.statics-block-wrapper {
    padding: 40px 0;
}
/* .statics-block{
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  overflow: hidden;
  margin: 0 0 24px 0;
  padding: 16px 20px;
  box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.15);
}
.statics-no h3{
  font-size: 30px;
  line-height: 1;
  margin-bottom: 10px;
}
span.statics-label {
  color: #888888;
}
.statics-block img.img-first{
  max-width: 80px;
  max-height: 80px;
  width: auto;
  height: 80px;
  position: absolute;
  top: 14px;
  right: 0;
  bottom: 0;
  opacity: 0.10;
  margin-right: -20px;
}
.statics-img{
  max-width: 36px;
    max-height: 36px;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
} */
.statistic-div-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.statistic-div {
    max-width: 50%;
}

    .statistic-div .statistic-shape {
        position: relative;
        max-width: 220px;
        margin: 0 0 20px;
    }

.statistic-shape .value {
    color: #fff;
    font-size: 42px;
    line-height: 42px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 42px;
    margin: auto;
    text-align: center;
}

.statistic-shape .statistic-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 75px;
    margin: auto;
    opacity: 0.50;
}

.panel .card-header {
    padding: 20px 30px;
    color: #212121;
    background-color: #fff;
    border-bottom: 3px solid rgba(217, 217, 217, 0.30);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ul.nav.nav-tabs.tab-light {
    background: #fff;
    padding: 0;
    border-radius: 6px;
    border-bottom: none;
}

.tab-light li.nav-item {
    margin-right: 30px;
}

.tab-light .nav-link {
    border: none;
    background-color: transparent;
    border-radius: 0;
    transition: 0.4s;
    font-weight: 500;
    padding: 0;
    color: #212121;
    border-bottom: none;
    font-size: 16px;
}

.panel .card-header .btn-circle {
    background: rgba(247, 146, 30, 0.10);
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
}

    .panel .card-header .btn-circle:hover {
        box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.16);
    }

.panel .card-body {
    padding: 20px 30px;
}

span.title {
    font-size: 16px;
    color: #212121;
    font-weight: 500;
    /**margin-right: 10px;**/
    /**width: 100%;**/
    width: 215px;
}

.btn-table {
    display: flex;
}

    .btn-table button.btn.btn-gray {
        margin-right: 10px;
    }

        .btn-table button.btn.btn-gray:last-child {
            margin-right: 0;
        }

.tab-light .nav-link.active {
    color: #F7921E;
}

    .tab-light .nav-link.active::before {
        content: " ";
        width: 20px;
        height: 3px;
        background: #91AE28;
        border-radius: 2px;
        bottom: -27px;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        display: inline-block;
    }

/***dashboard-pg CSS :: end***/


/*** faq-pg :: start ***/
.faq-block .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
/*** faq-pg :: end ***/

/*** profile-details-pg :: start ***/
.profile-details {
    padding: 20px;
    margin: 0 0 30px 0;
    position: relative;
}

    .profile-details:after {
        content: ' ';
        border-radius: 6px 6px 0px 0px;
        background: #F5F5F5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;
        height: 105px;
    }

.profile-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

    .profile-img img {
        height: 150px;
        width: auto;
        border-radius: 90px;
        border: 4px solid #FFF;
        z-index: 1;
    }

.profile-details .expert-name {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    display: block;
    margin: 0 0 10px;
}

.profile-details .meta-inline {
    display: flex;
    justify-content: space-between;
}

    .profile-details .meta-inline strong {
        text-align: right;
    }

.divider-line-20 {
    margin: 20px 0;
    background: rgba(0, 0, 0, 0.15);
    height: 1px;
    width: 100%;
}

.profile-details .work-pay {
    font-size: 24px;
}

.social-icon-sm li {
    margin-right: 10px;
}

    .social-icon-sm li:last-child {
        margin-right: 0;
    }

.profile-details-desc {
    padding: 0;
}

.profile-details-desc-title h6 {
    font-size: 24px;
    margin: 0 0 20px 0;
}

.profile-details-desc-title p {
    margin: 0 0 20px 0;
}

.profile-details-desc .company-meta h6 {
    font-size: 18px;
}

.profile-details-desc .btn-circle {
    background: rgba(247, 146, 30, 0.10);
    box-shadow: none;
    width: 60px;
    height: 60px;
    margin: 0 20px 0 0;
}

.company-meta-inline {
    display: flex;
    flex-direction: column;
}

    .company-meta-inline h5 {
        font-size: 16px;
    }

.inline-icon-info {
    display: flex;
}

.file-data {
    display: flex;
    align-items: center;
}

    .file-data a {
        text-decoration: underline;
    }

.profile-details-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

    .profile-details-btn .btn {
        margin: 0 20px 0 0;
        width: 100%;
        font-size: 16px;
    }

        .profile-details-btn .btn:last-child {
            margin: 0;
        }

ul.dropdown-menu.share-dropdown {
    margin: 20px 0 0 0;
    padding: 0;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.15)
}

.social-icon-sm {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-details-img {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

    .profile-details-img .expert-name {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .profile-details-img .light {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        margin: 0;
    }

.social-icon-sm img:hover {
    transform: translateY(-2px);
    transition: 0.4s;
}

.profile-jobs-block .icon-wrapper .m-icon {
    font-size: 18px;
}

.profile-jobs-block .icon-wrapper span {
    font-size: 18px;
}

.profile-jobs-block .accordion-wrapper .accordion-btn {
    padding: 0 0 20px 0;
}

.company img {
    border-radius: 50%;
}
/*** profile-details-pg :: end ***/

/*** edit-profile-cliet-pg :: start ***/
.profile-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
}

    .profile-wrapper .info-meta .m-icon {
        font-size: 24px;
    }

    .profile-wrapper .info-meta span {
        font-size: 18px;
    }

.client-profile .container-fluid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.client-profile .tab-wrapper {
    margin-bottom: 30px;
}

.client-profile .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.business-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

    .business-input label {
        font-size: 16px;
        font-weight: 600;
    }

.client-profile .form-control {
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0.15);
}

.form-control.upload {
    width: 160px;
    height: 160px;
    background: #F5F5F5;
    padding: 55px 30px;
}

.form-control.w-full {
    width: 100%;
}

.company-desc {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

    .company-desc textarea {
        resize: none;
    }

.client-profile .form-control.lg {
    width: 615px;
    max-width: 100%;
}

.client-profile #business .form-select {
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0.15);
    color: #888;
    font-size: 16px;
    font-weight: 400;
}

    .client-profile #business .form-select:focus {
        border-radius: 6px;
        border: 1px solid rgba(0,0,0,0.15);
    }


.breadcrumb {
    background: #F8F8F8;
    padding: 0;
}

.breadcrumb-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
}

.panel-action {
    display: flex;
}

.mandatory-field {
    margin-bottom: 30px;
}

.forn-group-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

    .forn-group-input label {
        font-size: 16px;
        font-weight: 400;
        color: #212121;
    }

.label-info {
    margin-bottom: 10px;
    color: #888;
}

.upload-file {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    border-radius: 6px;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    background: #FFF;
    padding: 5px 12px;
}

.file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.badge-btn-group {
    display: flex;
    flex-wrap: wrap;
}

.company-meta .badge {
    box-shadow: none;
    cursor: text;
    margin: 0 10px 10px 0;
}

    .company-meta .badge:hover {
        background: #fff;
        color: #888;
        border: 1px solid rgba(33, 33, 33, 0.50);
    }

.desc-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.company-desc textarea {
    resize: none;
}

.panel .card-footer {
    padding: 20px 30px;
    color: #212121;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 3px solid rgba(217, 217, 217, 0.30);
}

.client-profile .company-meta h6, .experts-profile .company-meta h6 {
    font-size: 16px;
    margin: 0 0 10px 0;
}

.profile-status-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    align-items: center;
    flex-wrap: wrap;
}

.profile-status {
    display: flex;
    align-items: center;
}

.profile-progressbar {
    display: flex;
    width: 100%;
    max-width: 340px;
}

.progress {
    width: 100%;
    max-width: 340px;
    height: 22px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.10);
}

.progress-bar {
    background: #F7921E;
    border-radius: 10px;
    /**width: 95% !important;**/
}

.tab-wrapper.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 1020;
    --bs-bg-opacity: 1;
}
/*** edit-profile-cliet-pg :: end ***/


/*** Feedback pop-up::start ***/
.feedback-btn {
    background: #212121;
    color: #fff;
    position: fixed;
    right: -26px;
    top: 0;
    bottom: 0;
    height: 30px;
    width: 84px;
    margin: auto;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 9;
}

.rate-wrap {
    display: flex;
    justify-content: center;
}

    .rate-wrap .rate-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        border: 1px solid rgba(0,0,0,0.15);
        margin: 0 8px;
        transition: 0.4s;
        cursor: pointer;
        margin-bottom: 40px;
        position: relative;
    }

        .rate-wrap .rate-btn:hover {
            background: rgba(247, 146, 30, 0.10);
            border: 1px solid transparent;
        }

        .rate-wrap .rate-btn.selected {
            color: #fff;
            background: #F7921E;
            border: 1px solid transparent;
        }

.ratings {
    display: flex;
    align-items: center;
    font-size: 16px;
}

    .ratings .m-icon {
        font-size: 20px;
    }
/*** Feedback pop-up::end ***/

/*** edit-experts-profile-pg :: start***/
.tab-wrapper-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.previous a, .next a {
    display: flex;
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    min-width: 100px;
}

.next a {
    justify-content: flex-end;
}

    .previous a:hover, .next a:hover, .previous a:focus-visible, .next a:focus-visible {
        color: #F7921E;
    }

.forn-group-input img {
    max-width: 70px;
    height: auto;
    border-radius: 70px;
}

.forn-group-input .btn-circle, .inline-icon-info .btn-circle {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    min-width: 60px;
    min-height: 60px;
    background: rgba(247, 146, 30, 0.10);
    box-shadow: none;
}

    .forn-group-input .btn-circle .m-icon, .inline-icon-info .btn-circle .m-icon {
        font-size: 32px !important;
    }

.forn-group-input .form-select {
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0.15);
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    padding: 12px 16px;
}

.table-responsive .form-control {
    border-radius: 6px;
    background: #FFF;
    color: #212121;
    font-family: 'Readex Pro', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    padding: 10px 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.table-responsive .form-select {
    border-radius: 6px;
    color: #888;
}

td .form-check {
    margin: auto;
    display: inline-block;
}

.table-responsive .form-select:focus {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.table-responsive .btn-circle.light {
    height: 36px;
    width: 36px;
    min-height: 36px;
    min-width: 36px;
}

.browse-btn {
    position: relative;
}

.browse-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.view-btn {
    position: relative;
}

.add-more .btn {
    box-shadow: none;
}

.tab-wrapper-block.sticky-top {
    background-color: #fff;
    --bs-bg-opacity: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 9;
}

.btn-edit {
    position: absolute;
    bottom: 0;
    left: 40px;
    min-width: 28px;
    min-height: 28px;
    padding: 0 !important;
    background: #F7921E;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .btn-edit .m-icon, .btn-icon-close .m-icon {
        font-size: 18px;
    }

.upload-doc-img {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.workers-img {
    position: relative;
    width: 90px;
    margin-right: 15px;
}

    .workers-img:last-child {
        margin-right: 15px;
    }

    .workers-img img {
        border-radius: 0;
        max-width: 90px;
        max-height: 90px;
        object-fit: cover;
        width: 90px;
        height: 90px;
        margin: 0;
    }

.btn-icon-close {
    bottom: auto;
    top: -5px;
    right: -5px;
    cursor: pointer;
    width: 22px;
    height: 22px;
    background: #FF4B55;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0;
    border: none;
}

.upload-doc .btn-circle .m-icon {
    font-size: 24px !important;
}

.file-btn {
    width: 150px;
}

.verification-status-wrap {
    background: #f5f5f5;
    padding: 15px;
    display: flex;
}

.verification-status-block {
    width: 100%;
}

    .verification-status-block .verification-list {
        display: flex;
        align-items: center;
        padding: 5px 0;
    }

.verification-list .material-icons {
    margin-right: 10px;
}

.verification-status-block .verification-list.completed {
    color: #F7921E;
}

.verification-status-block .verification-list.pending {
    color: #664d03;
}

/*** edit-experts-profile-pg :: end ***/

/*** company-details-pg ::start***/
.profile-details .client-name {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    display: block;
    margin: 0 0 10px;
}

span.company-tag {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
}
/*** company-details-pg :: end ***/

/*** message-pg :: start***/
.message-wrapper-block {
    display: flex;
    flex-wrap: nowrap;
}

.contacts-block-wrapper {
    max-height: calc( 100vh - 145px );
    overflow: auto;
}

.user-list {
    max-width: 300px;
    width: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    /**position: fixed;**/
}

    .user-list .search-info, .chat-header .search-info {
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 5px 10px;
        margin: 15px 20px 15px 20px;
    }

    .user-list .form-control, .chat-header .form-control {
        border: none;
        padding: 0;
        font-size: 14px;
    }

.contacts-block {
    margin: 20px 0 0 0;
}

.inline-meta {
    color: #888;
    font-size: 14px;
    font-weight: 400;
}

ul.contact-list a {
    display: flex;
    position: relative;
    padding: 10px 20px;
}

    ul.contact-list a.active {
        background-color: #f5f5f5;
    }

    ul.contact-list a:hover {
        background-color: #F8F8F8;
    }

.contact-img {
    position: relative;
    margin-right: 10px;
}

    .contact-img img {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        min-width: 42px;
        min-height: 42px;
        object-fit: cover;
    }

.contact-meta {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.contact-name {
    margin-bottom: 5px;
    color: #212121;
    font-size: 16px;
    font-weight: 400;
}

.online_icon {
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: #008000;
    border-radius: 50%;
    bottom: -2px;
    right: -2px;
    border: 2px solid white;
}

.chat-count {
    color: #fff;
    border: 1px solid #FFFFFF;
    border-radius: 40px;
    background: #F7921E;
    padding: 2px 6px;
    display: block;
    text-align: right;
    font-size: 11px;
    font-weight: 500;
    min-width: 20px;
    height: 20px;
    position: absolute;
    line-height: 15px;
    margin: auto;
    right: 20px;
    bottom: 10px;
}

.chat-time {
    color: #888;
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    line-height: 15px;
    margin: auto;
    right: 20px;
    top: 10px;
}

.title-sm {
    color: #888;
    padding: 0 0 0 20px;
}

.message-chat {
    width: 100%;
    /**padding-left: 300px;**/
    position: relative;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
}

ul.chat-history {
    padding: 20px 30px;
    height: calc( 100vh - 223px );
    overflow: auto;
}

.chat-body {
    display: flex;
    flex-direction: column;
}

.chat-info {
    color: #888;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.chat-name {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 400;
}

.chat-body .chat-bubble {
    border-radius: 6px 0px 6px 6px;
    padding: 10px;
    margin: 0 0 20px;
    max-width: 80%;
    color: #888;
    position: relative;
    border: 1px solid transparent;
    word-break: break-word;
    /* display: flex;
  align-items: flex-start; */
}

.chat-body.me .chat-bubble {
    background: rgba(247, 146, 30, 0.10);
}

.chat-body.other .chat-bubble {
    background: #F8F8F8;
}

.chat-body .chat-bubble p {
    margin: 0;
    padding-right: 20px;
    word-wrap: break-word;
}

.chat-body.me {
    align-items: flex-end;
}

.chat-bubble.highlighted {
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.16);
}

.msg-action .dropdown-menu {
    width: 120px;
    padding: 0;
    min-width: auto;
}

.msg-action ul.dropdown-menu li a {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #808080;
    padding: 8px 16px;
}

    .msg-action ul.dropdown-menu li a .material-icons {
        font-size: 20px;
        margin-right: 5px;
    }

.chat-body .img-bubble {
    border-radius: 6px 0px 6px 6px;
    padding: 10px;
    margin: 0 0 20px;
    max-width: 80%;
    color: #888;
    position: relative;
    display: flex;
}

.chat-body.me .img-bubble {
    background: rgba(247, 146, 30, 0.10);
}

.chat-body .img-bubble .img-div {
    position: relative;
    margin-right: 10px;
    max-width: 140px;
}

    .chat-body .img-bubble .img-div:last-child {
        margin-right: 0;
    }

.chat-body .img-bubble .btn-icon {
    background: rgba(0,0,0,0.25);
    color: #fff;
}

    .chat-body .img-bubble .btn-icon .m-icon {
        color: #fff;
    }

.date-divider {
    margin: 20px 0;
    position: relative;
    text-align: center;
    z-index: 1;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

    .date-divider span {
        position: absolute;
        top: -9px;
        background: #fff;
        padding: 0 20px;
        color: #888;
        font-size: 13px;
        margin: auto;
        left: 0;
        right: 0;
        width: 140px;
    }

.chat-body.other {
    align-items: flex-start;
    flex-direction: row;
}

.chat-body .msg-action {
    position: absolute;
    top: 8px;
    right: 8px;
}

.contact-img-sm {
    margin-bottom: 10px;
}

    .contact-img-sm img {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        object-fit: cover;
    }

.chat-file-name {
    white-space: pre-line;
    display: block;
    word-break: break-all;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.file-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 6px 6px 6px;
    background: rgba(247, 146, 30, 0.10);
    padding: 10px;
    margin: 10px 0;
    max-width: 80%;
    width: auto;
    max-width: 80%;
    transition: 0.4s;
}

.chat-body.me .file-block {
    background: rgba(247, 146, 30, 0.10);
}

.chat-body.other .file-block {
    background: #F8F8F8;
}

.file-block-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

    .file-block-wrap .file-block {
        min-width: 50% !important;
    }

        .file-block-wrap .file-block .chat-file-name {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

.file-img img {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    object-fit: cover;
    margin-right: 10px;
}

.file-text-sm {
    color: #888;
    font-size: 12px;
}

.file-action-btn {
    margin-left: 5px;
}

.chat-bubble .file-block {
    width: auto;
    max-width: 100%;
    background: transparent !important;
    border-radius: 6px;
}

.chat-body .file-block:hover {
    background: rgba(247, 146, 30, 0.10) !important;
}

.chat-input {
    position: relative;
    width: 100%;
}

    .chat-input textarea.form-control {
        border-radius: 6px;
        background: #F8F8F8;
        resize: none;
        font-size: 14px;
        padding: 16px 60px 16px 16px;
    }

.chat-left-icon button.btn-icon {
    position: absolute;
    left: 16px;
    top: 8px;
    color: #888;
    display: block;
}

    .chat-left-icon button.btn-icon .m-icon, .chat-right-icon button.btn-icon .m-icon {
        font-size: 24px !important;
    }

.chat-right-icon {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    height: 30px;
    margin: auto;
    display: block;
    cursor: pointer;
}

.file-input {
    position: relative;
    cursor: pointer;
}

    .file-input input {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }

.chat-input .file-input .btn-icon {
    height: 30px;
}

.chat-input .selected-file {
    position: relative;
}

    .chat-input .selected-file .btn-circle {
        cursor: default;
    }

    .chat-input .selected-file .btn-icon-close {
        width: 16px;
        height: 16px;
    }

.btn-icon-close .m-icon {
    font-size: 13px;
}

.chat-area-lg {
    display: flex;
    align-items: center;
    padding: 10px 30px 20px 30px;
    background: #fff;
    position: absolute;
    z-index: 1;
    left: 0; /**300px**/
    right: 0;
    position: relative;
}

    .chat-area-lg .lable {
        position: absolute;
        bottom: -16px;
        left: 0;
        font-size: 10px;
        color: #888;
    }

.back-arrow {
    display: none;
}

    .back-arrow .btn.btn-icon {
        margin-right: 10px;
        background: transparent;
        box-shadow: none;
    }

/*** message-pg :: end ***/

/*** settings-pg :: start***/
.settings-block .btn-icon {
    position: absolute;
    top: 42px;
    right: 12px;
    display: inline-block;
}

.settings-block .form-control {
    padding: 12px 35px 12px 16px;
}

.delete-account {
    display: flex;
    align-items: end;
    justify-content: end;
}
/*** settings-pg :: end ***/

/*** notification page :: start ***/
.notification-block {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 16px;
    padding: 15px 40px 15px 15px;
    position: relative;
    color: #212121;
    transition: 0.4s;
}

a.notification-block.new {
    font-weight: 700;
}

a.notification-block:hover {
    color: #212121;
}

.notification-block:hover {
    background: #f5f5f5;
}

.notification-block .text {
    display: block;
    margin: 0 0 5px;
}

.notification-block .time {
    display: block;
    font-weight: 300;
    color: #888;
}

.notification-block .close {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 15px;
}
/*** notification page :: end ***/

/*** assessment1 page :: start ***/
.assessment-list li {
    display: flex;
    margin-bottom: 20px;
}

.assessment-list .m-icon {
    color: #91AE28;
    margin-right: 20px;
}
/*** assessment1 page :: end ***/

/*** assessment2 page :: start ***/
.quiz h6 {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 500;
}
/*** assessment2 page :: end ***/

/*** contact-us page :: start ***/
.contact-us-block {
    display: flex;
}

    .contact-us-block .left-sec {
        width: 50%;
    }

    .contact-us-block .img-wrapper {
        height: auto;
        position: absolute;
        top: 80px;
        bottom: 80px;
        left: 0;
        max-width: 50%;
    }

    .contact-us-block img.img-block {
        object-fit: cover;
        height: auto;
        width: auto;
        min-height: 100%;
        min-width: 100%;
    }

    .contact-us-block .img-wrapper::after {
        content: " ";
        top: 0;
        bottom: 0;
        right: 0;
        left: 50%;
        position: absolute;
        background: rgb(255,255,255);
        background: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)), to(rgba(255,255,255,1)));
        background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }
/**background: linear-gradient(90deg, #FFF 0.41%, rgba(255, 255, 255, 0.50) 27.8%, rgba(255, 255, 255, 0.00) 99.65%);**/

.contact-block {
    padding: 0 60px;
    width: 50%;
    position: relative;
    z-index: 1;
}

.section.reach-us-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 9;
    position: relative;
}

.reach-us-block span.bg-wrap {
    max-height: 305px;
}

.reach-us-block .section-title {
    text-align: center;
}

.reach-us-block .step-block {
    padding: 20px;
    height: 100%;
}

    .reach-us-block .step-block img {
        max-width: 60px;
        height: auto;
        margin: 0 auto 30px auto;
    }

.reach-us-block h1 {
    margin-bottom: 50px;
}

.reach-us-block .step-block h6 {
    margin-bottom: 20px;
}

.maps-block {
    background: #eee;
    height: 380px;
}
/*** contact-us page :: end ***/

/*** Privacy-policy page :: start::21-09-23***/
.privacy-block .panel .card-header, .terms-block .panel .card-header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.privacy-block p, .terms-block p {
    font-size: 16px;
    margin-bottom: 20px;
}

.policy-list li, .terms-list li {
    display: flex;
    margin-bottom: 20px;
}

.policy-list .m-icon, .terms-list .m-icon {
    color: #91AE28;
    margin-right: 20px;
}
/*** Privacy-policy page :: end ***/

/*** Privacy-policy page :: end ***/

.tooltip-header {
    padding: 12px;
    border-bottom: 1px solid rgba(0,0,0,.15);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

    .tooltip-header h5 {
        font-size: 18px;
        margin: 0;
    }

.tooltip-meta {
    font-size: 13px;
    font-weight: 300;
    padding: 12px;
}

    .tooltip-meta p {
        font-size: 14px;
        margin: 0 0 10px;
        font-weight: 400;
    }

        .tooltip-meta p:last-child {
            margin: 0;
        }

    .tooltip-meta b {
        font-weight: 600;
    }
.vheight {
    margin-top: 150px !important;
}