@media screen and (max-width: 1400px) and (min-width: 1180px) {
    .search-compact {
        max-width: 240px;
    }
    header .menu-bar li.nav-item {
        margin-right: 20px;
    }
    header .navbar-brand img{
        height: 32px;
    }
    .mobile-offcanvas ul.navbar-nav.user-info .user-view .m-icon{
        margin: 10px 10px 10px 40px;
    }
    .search-compact .form-control {
        padding: 1px 2px 1px 15px;
    }
    .page-wrapper {
        padding: 68px 0 0 0;
    }
    .hide-lg
    {
        display:none;
    }
    .certified-list {
        width: max-content;
    }
}

@media screen and (max-width: 1366px) and (min-width: 1180px) {
    .container-fluid{
        padding: 0 40px;
    }
    header ul.navbar-nav.menu-bar a.nav-link{
        font-size: 14px;
    }
    .hide-lg {
        display: none;
    }
    .certified-list {
        width: max-content;
    }
}

/** Tablet potrait 768-1180****/
@media screen and (max-width: 1180px) {
    .location-width {
        width: 250px !important;
    }
    /******responsive-view::start::07-07-2023******/
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 16px 0 !important;
    }
    header ul.navbar-nav.menu-bar li.nav-item {
        margin-right: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    ul.navbar-nav.user-info li.nav-item {
        margin:0;
    }
    ul.navbar-nav.menu-bar span.resp-display {
        display: block;
      }
    ul.navbar-nav.user-info-btn{
        flex-direction: row;
        justify-content: space-between;
        position: fixed;
        left: 0;
        right: 0;
        padding: 0 20px;
        bottom: 20px;
    }
    .resp-display{
        display: inline-block;
    }
    header .navbar-brand {
        margin: 0 0 15px;
        display: block;
    }
    .navbar-brand img.logo{
        height: 28px;
    }
    .respo-header {
        width:100vw;
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 1030;
        border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    }
    .page-wrapper {
        padding: 68px 0 0 0;
    }
    .respo-header ul.navbar-nav.user-info{
        flex-direction: row;
    }
  
    .respo-header ul.navbar-nav.user-info li.nav-item.mobile-view-none {
        display: block;
        margin: 0 20px 0 0;
    }
    .respo-header .btn-icon span{
        font-size: 24px;
    }
    .respo-header .btn-icon{
        margin-right: 16px;
        padding: 0;
    }
    header .respo-header .navbar-brand {
        margin: 0;
    }
    .respo-header .btn.outline-btn{
        padding: 6px;
        border: 1px solid #212121;
        color: #212121;
        background: #FFF;
        box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.16);
    }
    .respo-header .btn.outline-btn span.m-icon{
        font-size: 24px;
    }
    .vheight {
        margin-top: 280px !important;
    }
    ul.navbar-nav.user-info li.nav-item.mobile-view-none {
        display: none;
    }
    ul.navbar-nav.user-info.respo-view li.nav-item{
        margin-right: 20px;
    }
    ul.navbar-nav.user-info.respo-view li.nav-item:last-child{
        margin-right: 0;
    }
    .mobile-offcanvas .container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }
    .mobile-offcanvas a.navbar-brand{
        order: 1;
        width: 100%;
    }
    .mobile-offcanvas ul.navbar-nav.user-info{
        order: 2;
        width: 100%;
    }
    .mobile-offcanvas ul.navbar-nav.menu-bar{
        order: 3;
        width: 100%;
        flex-direction: column;
    }
    .mobile-offcanvas ul.navbar-nav.user-info li.nav-item.dropdown {
        margin-bottom: 20px;
    }
    .mobile-offcanvas ul.navbar-nav.user-info li.nav-item.dropdown:last-child{
        margin-bottom: 0;
    }
    a.nav-link.dropdown-toggle.p-0.shadow-none.d-flex{
        width: 100%;
        display: block;
    }
    .mobile-offcanvas ul.navbar-nav.user-info .user-view{
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .mobile-offcanvas ul.navbar-nav.user-info-btn li.nav-item{
        width: 100%;
    }
    ul.navbar-nav.menu-bar a.nav-link.dropdown-toggle{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .mobile-offcanvas ul.dropdown-menu {
        margin: 10px 0 0 0;
        padding: 0;
        border-radius: 0;
        border: none;
        background: #F8F8F8;
        box-shadow: none;
    }
    header .menu-bar li.nav-item a.nav-link.active::before {
        top: 0;
        left: -20px;
        right: auto;
        height: 25px;
        width: 3px;
        bottom: 0;
        margin: auto;
    }
    ul.navbar-nav.user-info li a.notification-menu.dropdown-toggle.show::after{
        bottom: -20px;
    }
    .count-msg{
        position: static;
    }
    .dropdown-toggle.show::after {
        display: none;
    }
    .fix-inner-block .fixed-top{
       position: static;
    }
    .fix-inner-block .sidebar {
        display: none;
    }
    .fix-inner-block .sidebar.show {
        display: block;
        position: fixed;
        left: 0;
        z-index: 1;
        top: 73px;
        width: 320px;
    }
    /* .fix-inner-block .sidebar.show::after{
        content: " ";
        top: 0;
        bottom: 0;
        right: 0;
        left: 319px;
        position: fixed;
        background: rgba(0, 0, 0, 0.40);
    } */
    .sidebar .sidebar-btn{
        width: 320px; 
        /* right: 0; */
    }
    .pg-content-block {
        padding: 15px 15px 60px 15px;
    }
    .fix-inner-block footer {
        padding-left: 0;
    }
    .exports-group .card {
        padding: 20px;
    }
    /******responsive-view::end::07-07-2023******/

    /******index:start******/
    .container-fluid {
        padding: 0 20px;
    }
    .section {
        padding: 80px 0;
    }
    .welcome-sec .left-sec {
        padding: 30px;
    }
    .section-sm {
        padding: 20px 0;
    }
    h1 {
        font-size: 36px;
    }
    .frame-content-block {
        padding: 0 20px;
    }
    .expert-info-block {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .expert-wrapper-block {
        margin: 0 0 30px 0;
        width: 48%;
    }
    .step-block {
        margin-right: 30px;
    }
    .comprehensive-block{
        text-align: center;
    }
    .comprehensive-block-title {
        flex-direction: column;
    }
    .comprehensive-btn {
        justify-content: center;
    }
    .comprehensive-block p {
        margin: 30px 0 20px 0;
    }
    /******index:end******/

    /******index-login:start******/
    ul.dropdown-menu.user-profile-list.show::after {
        /**top: 43px;
        left: 5px;**/
        display: none;
    }
    ul.dropdown-menu.profile-list.show::after {
        /**top: 40px;
        left: 5px;**/
        display: none;
    }
    .search-compact{
        display: none;
    }
    /******index-login:end******/

    /******search-job-pg:start::23-08-2023******/
    ul.nav.nav-tabs li.nav-item .filter-btn {
        display: flex;
    }
    /******search-job-pg:end******/

    /******message-pg:start::28-08-2023******/
    .contact-name {
        font-size: 14px;
    }
    .contact-list .contact-meta .inline-meta {
        font-size: 12px;
    }
    .chat-header {
        padding: 12px 20px;
    }
    ul.chat-history {
        padding: 20px;
    }
    .chat-area-lg {
        padding: 10px 20px 20px 20px;
    }
    .chat-body p {
        font-size: 13px;
    }
    .hide-lg {
        display: none;
    }
    .certified-list {
        width: max-content;
    }
    /******message-pg:end::28-08-2023******/
}

@media screen and (max-width: 1024px) {
    .min-lg-cell{
        min-width: 200px;
    }
    .min-md-cell{
        min-width: 140px;
    }
    .hide-lg {
        display: none;
    }
    .certified-list {
        width: max-content;
    }
}

@media screen and (max-width: 992px) {
    .certified-list {
        width: max-content;
    }
    .experts-profile .tab-btn{
        font-size: 13px;
        padding: 8px 8px;
    }
    .previous a, .next a{
        font-size: 14px;
        min-width: 90px;
    }
    .previous a .material-icons, .next a .material-icons{
        font-size: 20px;
    }
    .exports-group ul.tab-wrapper.nav.nav-tabs a.btn.tab-btn{
        display: none;
    }
    .hide-md {
        display: none !important;
    }
    .show-md {
        display: block !important;
    }
    .profile-details .meta-inline
    {}
}

@media screen and (max-width: 820px) {
    .certified-list {
        width: max-content;
    }
    .experts-profile .tab-btn{
        display: none;
    }
    .hide-md {
        display: none !important;
    }
    .show-md {
        display: block !important;
    }
    .experts-profile .tab-btn.active{
        display: block;
        margin: 0 !important;
    }
    .experts-profile .tab-btn{
        font-size: 16px;
        padding: 10px 15px;
    }
    .previous a, .next a{
        font-size: 16px;
        min-width: 80px;
    }
}

@media screen and (min-width: 768px) {
    .user-list {
        max-width: 260px;
    }
    .certified-list {
        width: max-content;
    }
}

/** mobile potrait 768 :: start**/
@media screen and (max-width: 767px) {
    .certified-list {
        width: max-content;
    }
    .tab-light li.nav-item{
        margin-bottom: 10px;
    }
    .previous a, .next a{
        font-size: 14px;
    }
    .previous a .m-icon, .next a .m-icon{
        font-size: 20px;
    }
    .forn-group-input label{
        font-size: 14px;
    }
    /** Home/index :: start**/
    .card {
        box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.15);
    }
    .navbar-brand img.logo {
        height: 22px;
    }
    .gradient-img {
        padding-bottom: 0;
    }
    .container {
        padding: 0 20px;
    }
    .search-btn span.m-icon{
        margin-right: 0;
    }
    .welcome-sec span.text-none, .pg-content-block .search-group span.text-none{
        display: none;
    }
    .welcome-sec,.ideal-group{
       flex-wrap: wrap;
    }
    .welcome-sec .left-sec {
        padding: 20px 20px 40px 20px;
        width: 100%;
    }
    h1 {
        font-size: 28px;
    }
    .welcome-sec .right-sec {
        width: 100%;
    }
    .welcome-sec .img-wrapper {
       position: relative;
       max-width: 100%;
    }
    .welcome-sec .img-wrapper::after {
        /* background: rgb(248,248,248);
        background: -webkit-gradient(linear, left bottom, left top, from(rgba(248,248,248,0)), to(rgba(248,248,248,1)));
        background: -o-linear-gradient(bottom, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%);
        background: linear-gradient(0deg, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%); */
        display: none;
    }
    .welcome-sec .section-desc {
        font-size: 16px;
    }
    .section-sm {
        padding: 20px 0;
    }
    h6 {
        font-size: 18px;
    }
    .logo-img-block img {
        /* max-width: 90px; */
        max-height: 80px;
        margin-right:15px;
    }
    .ideal-group .left-sec{
        width: 100%;
        order: 1;
    }
    .ideal-group .img-wrapper{
        position: relative;
        max-width: 100%;
    }
    .ideal-group .img-wrapper::after{
        /* background: rgb(248,248,248);
        background: -webkit-gradient(linear, left bottom, left top, from(rgba(248,248,248,0)), to(rgba(248,248,248,1)));
        background: -o-linear-gradient(bottom, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%);
        background: linear-gradient(0deg, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%); */
        display: none;
    }
    .frame-content-block{
        padding: 20px;
        width: 100%;
    }
    .frame-content-block .section-title h1 {
        margin-bottom: 30px;
    }
    .frame-data p {
        font-size: 14px;
    }
    .frame-content-block .btn.btn-sm.btn-orange{
        margin: 0 0 20px 0;
    }
    span.bg-wrap {
        max-height: 330px;
    }
    .how-it-works .tab-wrapper{
        margin-bottom: 50px;
    }
    .step-wrapper{
        display: block;
    }
    .step-block {
        margin: 0 0 45px 0;
    }
    .step-wrapper .step-block:last-child{
        margin: 0;
    }
    .featured-experts-title {
        margin-bottom: 20px;
    }
    .expert-info-block {
        display: block;
    }
    .expert-wrapper-block {
        margin: 0 0 30px 0;
        width: 100%;
    }
    .expert-info-block .expert-wrapper-block:last-child {
        margin: 0;
    }
    .tab-mobile-hide {
        display: none;
    }
    .tab-mobile-display {
        display: flex;
        justify-content: center;
        margin: 30px 0 0 0;
        align-items: center;
    }
    .comprehensive-block p {
        margin: 15px 0;
    }
    footer {
        padding: 40px 0;
    }
    footer p.footer-desc {
        font-size: 16px;
    }
    .footer-logo img {
        margin-bottom: 20px;
    }
    .menu {
        margin: 30px 0 30px 0;
    }
    ul.menu-list{
        flex-direction: column;
    }
    .menu-list li {
        margin: 0 0 20px  0;
    }
     /** Home/index :: end**/

    /** all-modal-index-pg :: start**/
    .login-info h1 {
        margin-bottom: 10px;
    }
    .login-info-text {
        margin: 30px 0 0;
    }
    .or-divider {
        margin:20px 0;
    }
    .verification-mail {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    /** all-modal-index-pg :: end**/

    /** search-telent :: start**/
    .pg-content-block {
        padding: 20px 20px 60px 20px;
    }
    .list-block .expert-name{
        font-size: 18px !important;
    }
    .exports-group .card {
        padding: 16px !important;
    }
    .exports-group .card-lg {
        padding: 20px;
    }
    #list {
        display: none;
    }
    #grid-view {
        display: block;
    }
    .list-block.grid-block .expert-name {
        font-size: 18px;
    }
    .list-block.grid-block .expert-data{
        margin-bottom: 10px;
    }
    .action-btns {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 20px 0 0 0;
    }
    .list-block .work-pay {
        font-size: 18px !important;
        min-width: 100px;
        text-align: left;
        margin: 10px 0 0;
    }
    /** search-telent :: end**/

    /** job-details-pg :: start**/
    .job-details-block .card {
        padding: 0;
        border: none;
        box-shadow: none;
    }
    .post-info-block{
        display: block;
    }
    .company-block{
        display: block;
    }
    .company-title h3, .company-post {
        margin-bottom: 20px;
    }
    .icon-info-block {
        flex-direction: column;
    }
    .icon-info-block .info-meta{
        margin: 0 0 10px 0;
    }
    .company-action {
        margin: 10px 0 0 0;
    }
    .icon-wrapper {
        margin: 0 0 20px 0;
    }
    .company-meta .badge {
        margin: 0 10px 10px 0;
    }
    .divider-line {
        margin: 20px 0;
    }
    /** job-details-pg :: end**/

    /** profile-details-pg :: start**/
    .card.profile-details-desc {
        padding: 40px 0 0 0;
        border: none;
        box-shadow: none;
    }
    .profile-details-desc-title h6 {
        font-size: 18px;
    }
    .profile-details-desc-title p {
        margin: 0;
    }
    .profile-details-desc .btn-circle {
        width: 42px;
        height: 42px;
        min-width: 42px;
        min-height: 42px;
    }
    .inline-icon-info .btn-circle .m-icon{
        font-size: 28px !important;
    }
    .inline-icon-info .m-icon{
        font-size: 20px;
    }
    .profile-details-img .light {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }
    /**profile-details-pg :: end**/

    /**dashboard-pg :: start**/
    .statics-block img.img-first{
        display: none;
    }
    .statics-block {
        padding: 12px;
        margin: 0px 0 20px 0;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.15);
    }
    .statics-no h3 {
        margin-bottom: 5px;
        font-size: 28px;
    }
    span.statics-label {
        font-size: 12px;
    }
    .statics-img {
        top: 12px;
        bottom: auto;
        right: 12px;
    }
    .tab-light .nav-tabs li.nav-item {
        margin-right: 20px;
    }
    .panel .card-header {
        padding: 10px 16px;
    }
    .tab-light .nav-tabs .nav-link.active::before {
        bottom: -7px;
    }
    .tab-light .nav-link.active::before{
        bottom: -13px;
    }
    .accordion-dashboard .accordion-btn {
        padding: 20px 0 20px 0;
    }
    .accordion-dashboard .accordion-btn h5{
        font-size: 16px;
    }
    .tab-light .nav-tabs .nav-link {
        font-size: 14px;
    }
    span.title {
        font-size: 14px;
    }
    .table-striped>tbody>tr:nth-of-type(odd)>* ,.table-striped>tbody>tr:nth-of-type(even)>*{
        font-size: 13px;
    }
    .table-responsive .m-icon{
        font-size: 18px;
    }
    /**dashboard-pg :: end**/

    /**edit-profile-client-pg :: start**/
    .breadcrumb-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
    }
    .breadcrumb-wrapper h4 {
        font-size: 24px;
    }
    .breadcrumb-wrapper .info-meta span {
        font-size: 16px;
    }
    .breadcrumb-wrapper .info-meta .m-icon {
        font-size: 20px;
    }
    .panel-action.mobile-none{
        display: none;
    }
    .panel .card-body {
        padding: 16px;
    }
    .mandatory-field {
        margin-bottom: 20px;
    }
    .forn-group-input {
        margin-bottom: 20px;
    }
    .card-footer.desktop-hide {
        display: flex;
        padding: 0 20px 20px 20px;
        border-top: 0;
    }
    .text-or{
        margin-bottom: 20px;
    }
    .company-meta h6 {
        font-size: 16px;
        margin: 0 0 10px 0;
    }
    .panel .card-footer {
        padding: 20px 20px;
    }
    .desc-info {
        flex-direction: column;
    }
    .profile-status {
        margin-bottom: 20px;
    }
    /**edit-profile-client-pg :: end**/

    /**search-job-pg ::start::23-08-2023**/
    .fix-inner-block .sidebar.show {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
        top: 73px;
    }
    .sidebar .sidebar-btn{
        width: 100%;
    }
    /**search-job-pg :: end**/

    /**message-pg :: start::28-08-2023**/ 
    .back-arrow {
        display: block;
    }
    .chat-left-icon button.btn-icon .m-icon, .chat-right-icon button.btn-icon .m-icon {
        font-size: 26px !important;
    }
    .chat-header {
        padding: 10px 20px;
    }
    /**.chat-header .form-control {
        width: 30px;
        transition: 0.4s;
    }
    .chat-header .form-control:focus {
        width: 90%;
        position: absolute;
        left: 20px;
        right: 20px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 14px 20px;
    }***/
    .contacts-block-wrapper {
        max-height: calc( 100vh - 161px );
    }
    ul.chat-history {
        max-height: calc( 100vh - 180px );
    }
    .chat-input textarea.form-control {
        font-size: 14px;
    }
    .user-list.show{
        display: block;
        max-width: 100%;
    }
    .message-chat.hide{
        display: none;
    }
    .user-list.hide{
        display: none;
    }
    .message-chat.show{
        display: block;
    }
    /**message-pg :: end::28-08-2023**/

    /**contact-us-pg :: start::16-09-2023**/
    .contact-us-block{
        flex-wrap: wrap;
    }
    .contact-us-block .left-sec {
        width: 100%;
        order: 1;
    }
    .contact-us-block .img-wrapper {
        max-width: 100%;
        position: relative;
    }
    .contact-us-block .img-wrapper::after {
        bottom: 50%;
        left: 0;
        background: rgb(248,248,248);
        background: -webkit-gradient(linear, left bottom, left top, from(rgba(248,248,248,0)), to(rgba(248,248,248,1)));
        background: -o-linear-gradient(bottom, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%);
        background: linear-gradient(0deg, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%);
    }
    .contact-block {
        width: 100%; 
        padding: 0 20px;
    }
    /**contact-us-pg :: end::16-09-2023**/

    #for-clients .accordion-wrapper .accordion-btn h5, #for-experts .accordion-wrapper .accordion-btn h5{
        font-size: 20px;
    }
    #for-clients .accordion-body, #for-experts .accordion-body{
        padding: 20px 20px 0;
    }

    .user-list {
        max-width: 100%;
    }
    .modal-content .btn-close {
        top: 8px;
        right: 8px;
        font-size: 16px;
    }

    .statistic-div-wrapper{
        justify-content: flex-start;
    }
}
/** mobile potrait 768 :: end**/
@media screen and (max-width: 520px) {
    .expert-info-details{
        flex-wrap: wrap;
    }
    .list-block.grid-block .expert-data{
        width: 100%;
    }
    .certified-list {
        width: 100% !important;
    }
}

@media screen and (max-width: 414px) {
    .verification-status-wrap .f-36 {
        font-size: 28px;
        margin-right: 10px !important;
    }
    .vheight {
        margin-top: 40px !important;
    }
    .certified-list {
        width: 100% !important;
    }
    .react-chatbot-kit-chat-container {
        width: 260px !important;
        bottom: 00px;
        right: 0px;
        border-radius: 0px;
        border: 2px solid #f1f1f1 !important;
    }
}

@media screen and (max-width: 380px) {
    .react-chatbot-kit-chat-container {
        width: 260px !important;
        bottom: 0px;
        right: 0px;
        border-radius: 0px;
        border: 2px solid #f1f1f1 !important;
    }
    .profile-status-wrapper
    {
        font-size:14px;
    }
    .navbar-nav.user-info .notification-list {
        width: 320px;
    }
    .logo-img-block img {
        /* max-width: 80px; */
        max-height: 70px;
    }
    .user-info ul.dropdown-menu li, .menu-bar ul.profile-list li {
        padding: 10px;
    }
    .verification-status-wrap .f-36 {
        font-size: 28px;
        margin-right: 15px;
    }
    .statistic-shape .value{
        font-size: 36px;
        line-height: 36px;
        height: 36px;
      }
      .statistic-shape .statistic-icon{
        height: 60px;
      }
    .vheight {
        margin-top: 40px !important;
    }
    .certified-list {
        width:100% !important;
    }
   
}