.errortext {
    color: #F00;
    font-size: 12px;
    font-weight: 200;
}
.w-p80{
    width:80%;
}
.w-p33 {
    width: 33%;
}
.f-52 {
    font-size: 52px;
}
.loader-base {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: gray;
    z-index: 99999999;
    opacity: .4;
}
.pop-greyed {
    position: static;
    background-color: #212121;
    opacity: .5;
}
.zindex {
    z-index: 99999999;
}
.bg-green {
    background: #91ae28 !important;
}
.file-block-wrap {
    justify-content: flex-start !important;
}
.popover {
    max-width: 70vw !important;
    z-index:20000;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.16);
}
.imgsize {
    max-height: 40px !important;
    max-width: 40px !important;
}
.pop-title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.loader-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 99999999999;
    opacity: 1;
}
.search-group {
    max-width: 680px;
}
.form-control.error {
    border: 1px solid #F00;
    box-shadow: 1px 3px 4px 0px rgba(255, 0, 0, 0.25) inset;
}
.form-select.error {
    border: 1px solid #F00;
    box-shadow: 1px 3px 4px 0px rgba(255, 0, 0, 0.25) inset;
}
.font-italic {
    font-style: italic;
}
.btn-round {
    border-radius: 50% ;
    height: 42px;
    width: 42px;
}
.line {
    line-height: normal;
}
.font-italic {
    font-style:italic;
}
.bg-black{
    background-color:black;
}
.pointer {
    cursor: pointer;
}
.padding-select {
    padding-bottom: 3px !important;
    padding-top: 4px !important;
    padding-left: 5px !important;
}
.padding0-select {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 5px !important;
}
.textarea-m {
    height: 500px;
}
.textarea-h {
    height: 150px;
}
.table-title {
    font-size: 16px;
    color: #212121;
    font-weight: 500;
    align-content:flex-start;
}
span.title {
   
    text-align: left !important;
}
.movingLoader {
    animation: animateBg 2s infinite linear !important;
    opacity: .5 !important;
    background-color: #fff !important;
    background-image: linear-gradient(90deg, #d3d3d3,#bdbdbd,#d3d3d3,#bdbdbd) !important;
    background-size: 300% 100% !important;
}

@keyframes animateBg {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}
.attachments-img
{
    width:100% !important;
}

.tablerow {
    vertical-align: top !important;
}
.btn-table {
    align-items: center;
    justify-content: center;
}
.text-capi {
    text-transform: capitalize !important;
}
.text-upper {
    text-transform: uppercase;
}
.text-lower {
    text-transform: lowercase;
}

.react-datepicker-popper {
    z-index: 2500 !important;
}
.rate-wrap .rate-btn {
    margin-bottom: 10px !important;
}
.popover {
    position: absolute;
    top: 0;
    left: 0 /* rtl:ignore */;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

    .popover .popover-arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
    }

        .popover .popover-arrow::before, .popover .popover-arrow::after {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
    bottom: calc(-0.5rem - 1px);
}

    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
        bottom: 1px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #fff;
    }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
}

    .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
        left: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #fff;
    }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
    top: calc(-0.5rem - 1px);
}

    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: #fff;
    }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
}

    .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #fff;
    }

.popover-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

    .popover-header:empty {
        display: none;
    }

.popover-body {
    padding: 1rem 1rem;
    color: #212529;
}

.chatbot-overlay {
    left: auto;
    right: 0px;
    position: fixed;
    bottom: 0px;
    z-index: 999999999;
}
.react-chatbot-kit-chat-input-container{
    display:none !important;
}
/** Chat- bot**/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.react-chatbot-kit-chat-message-container
{
    overflow-x:hidden !important;
    overflow-y:scroll !important;
    height:444px;
}
.react-chatbot-kit-chat-container {
    width: 380px;
    bottom: 0px;
    right: 0px;
    border-radius: 0px;
    border: 2px solid #f1f1f1 !important;
}

.react-chatbot-kit-chat-bot-message {
    margin-left: 10px !important;
    background-color: #f1f1f1 !important;
    color: #585858 !important;
    width:100%;
}

.react-chatbot-kit-user-avatar-container {
    width: 35px;
    height: 35px;
    background-color: #F7921E !important;
}

.react-chatbot-kit-chat-bot-message-arrow {
    border-right: 8px solid #f1f1f1 !important;
}


.react-chatbot-kit-chat-btn-send {
    background-color: #91AE28 !important;
}

.chat-avatar {
    background-color: #91AE28;
    width: 35px;
    height: 35px;
    display: grid;
    place-items: center;
    color: white;
    border-radius: 100%;
    font-weight: 600;
}

.start-btn {
    background-color: #91AE28;
    color: white;
    padding: .5rem 1rem;
    border-radius: 999px;
    border: none;
    cursor: pointer;
    margin-left: 3rem;
}

.slow-btn {
    margin-left: 1rem;
}

.img-component {
    margin-left: 3rem;
    position: relative;
}

.img-container {
    width: 185px;
    height: 185px;
    border: 2px solid #91AE28;
    overflow: hidden;
    border-radius: 5px;
}

    .img-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

.img-component a {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    background-color: #91AE28;
    color: white;
    text-decoration: none;
    padding: .5rem 1rem;
}

    .img-component a:hover {
        background-color: #91AE285e;
    }

.chatbot-bubble {
    width: 64px;
    height: 64px;
    border-radius:32px;
    background-color: white;
   right:18px;
   bottom:18px;
    position: fixed;
   
    cursor: pointer;
    
   
    border: none;

    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(145, 174, 40) !important;
    z-index: 9999999;
}
.chatbot-bubble-in {
    position: relative;
    overflow: hidden;
    border-radius: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(145, 174, 40);

}
.scroll-to-top {
    z-index:999998;
    right: 30px!important;
    bottom: 100px !important;
    
}
.width-max {
    width: fit-content;
}
.location-width {
    width: 170px;
}